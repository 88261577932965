<template>
  <div style="border: none">
    <div v-if="settingValue === 2">
      <div v-if="display == 3">
        Estimated Customers : {{ estimatedCustomer.userSend | numeral("0,0") }}

        {{
          form.is_not_consent_send == 0 || form.is_not_consent_send == 2
            ? `(Total
        ${estimatedCustomer.userAll.toLocaleString()} - ${
                isNaN(estimatedCustomer.userPercen)
                  ? 0
                  : (estimatedCustomer.userPercen * 100).toFixed(2)
              }%)`
            : "(All)"
        }}
      </div>
      <div>Condition : Unconditional</div>
    </div>
    <div v-else>
      <div v-if="display === 4">
        <div v-for="(action, i) in this.form.action" :key="i">
          <span v-show="false">{{ action }}</span>
          <b-row v-if="action.action_type_id === 1">
            <b-col cols="12" md="3" lg="2"
              >{{ action.action_type_name }} :</b-col
            >
            <b-col cols="9" class="font-w-600">{{
              valueOfRedeem(action.action_value)
            }}</b-col>
          </b-row>
          <b-row v-if="action.action_type_id === 2">
            <b-col cols="12" md="3" lg="2"
              >{{ action.action_type_name }} :</b-col
            >
            <b-col cols="9" class="font-w-600"
              >{{ action.action_value }} Point</b-col
            >
          </b-row>
        </div>
      </div>
      <div v-else-if="display === 3">
        Estimated Customers : {{ estimatedCustomer.userSend | numeral("0,0") }}
        {{
          form.is_not_consent_send == 0 || form.is_not_consent_send == 2
            ? `(Total
        ${estimatedCustomer.userAll.toLocaleString()} - ${
                isNaN(estimatedCustomer.userPercen)
                  ? 0
                  : (estimatedCustomer.userPercen * 100).toFixed(2)
              }%)`
            : "(All)"
        }}
        <font-awesome-icon
          icon="download"
          class="pointer mx-2 clarins-color"
          @click="openModal"
        />
        <div v-for="(group, i) of form.condition_group" :key="'group' + i">
          <b> Group : {{ group.group }}</b>
          <div v-for="(item, index) in group.condition" :key="index">
            <b-row>
              <b-col md="6" lg="4">{{ item.condition_type_name }} : </b-col>
              <b-col md="6" lg="8" class="font-w-600">
                <span
                  class="mr-3"
                  v-if="
                    getTypeOfOptions(item.condition_type_id) == 'int' ||
                    getTypeOfOptions(item.condition_type_id) == 'Datetime'
                  "
                >
                  {{ item.operation_type_name }}
                </span>
                <span
                  v-if="getTypeOfOptions(item.condition_type_id) == 'Datetime'"
                  >{{
                    $moment(item.condition_value).format(
                      "DD/MM/YYYY hh:mm:ss A"
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    getTypeOfOptions(item.condition_type_id) == 'Dropdown'
                  "
                  >{{
                    getNameOfValue(
                      item.condition_value,
                      getObjFromId(item.condition_type_id)
                    )
                  }}</span
                >
                <span v-else>{{ item.condition_value }} </span>
                <span v-if="item.operation_type_name == 'between'">
                  -
                  <span
                    v-if="
                      getTypeOfOptions(item.condition_type_id) == 'Datetime'
                    "
                    >{{
                      $moment(item.between_value).format(
                        "DD/MM/YYYY hh:mm:ss A"
                      )
                    }}
                  </span>
                  <span v-else>{{ item.between_value }}</span></span
                >
              </b-col>
            </b-row>
          </div>
          <div class="my-2" v-if="group.operation_type">
            <b> {{ group.operation_type.toUpperCase() }}</b>
          </div>
        </div>
      </div>
    </div>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="formExport"
      @closeModal="clearExportFilter"
      @changeEmail="(val) => (formExport.email = val)"
      @submit="exportCustomer"
    />
  </div>
</template>

<script>
import ModalInputEmail from "@/components/customer/ModalInputEmail";
export default {
  components: {
    ModalInputEmail,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    display: {
      required: true,
      type: Number,
    },
    settingValue: {
      required: true,
      type: Number,
    },
    privilegeList: {
      required: false,
      // type: Array,
    },
    serviceList: {
      required: false,
    },
    branchList: {
      required: false,
    },
    productList: {
      required: false,
    },
    campaignList: {
      required: false,
    },
    stampList: {
      required: false,
      // type: Array,
    },
    gender: {
      required: false,
      // type: Array,
    },

    memberLevel: {
      required: false,
      type: Array,
    },
    redeemCodeList: {
      required: false,
    },
    conditionList: {
      required: false,
    },
    campaignFieldConditions: {
      required: false,
    },
    estimatedCustomer: {
      required: false,
    },
  },
  data() {
    return {
      formExport: {
        email: "",
        condition_group: [],
      },
    };
  },
  methods: {
    getTypeOfOptions(id) {
      return this.conditionList.find((el) => el.id == id).type;
    },
    getObjFromId(id) {
      if (id == 1) return this.memberLevel;
      if (id == 2) return this.gender;
      else if (id == 7) return this.privilegeList;
      else if (id == 11) return this.stampList;
      else if (id == 16 || id == 23 || id == 25) {
        return this.branchList;
      } else if (id == 17) return this.productList;
      else if (id == 18) return this.campaignList;
      else if (id == 27) return this.serviceList;
      else if (id == 34)
        return [
          { id: "1", name: "Yes" },
          { id: "0", name: "No" },
        ];
    },
    getNameOfValue(val, obj) {
      if (typeof val == "object")
        return val
          .map((els) => obj.find((el) => el.id == els).name)
          .join(" , ");
      else {
        let value = obj.find((el) => el.id == val);
        return value?.name;
      }
    },
    valueOfRedeem(val) {
      let value = this.redeemCodeList.find((el) => el.id == val);
      return value ? value?.name : val;
    },

    getFieldCampaign(val) {
      let choice = this.campaignFieldConditions.find((n) =>
        n.field_choices.find((i) => i.id == val)
      );
      if (choice) return choice.name;
      return "";
    },
    getFieldCampaignChoice(val) {
      let choice = this.campaignFieldConditions.find((n) =>
        n.field_choices.find((i) => i.id == val)
      );
      if (choice) return choice.field_choices.find((el) => el.id == val).name;
      return "";
    },

    valueOfStampCard(val) {
      let value = this.stampList.find((el) => el.id == val);

      return value ? value?.name : val;
    },
    openModal() {
      this.$refs.ModalInputEmail.show();
    },
    async exportCustomer() {
      let object = JSON.parse(JSON.stringify(this.form.condition_group));
      for (const group of object) {
        var tempObj = [];

        for (const list of group.condition) {
          if (typeof list.condition_value == "object") {
            // console.log(list);
            for (const value of list.condition_value) {
              tempObj.push({ ...list, condition_value: value });
            }
          } else {
            tempObj.push(list);
          }
        }

        group.condition = tempObj;
      }

      this.$EventBus.$emit("showLoading");
      this.formExport.condition_group = object;
      const resp = await this.$Axios.post(
        `${process.env.VUE_APP_API}/MarketingAutomation/export_customer_condition_list`,
        this.formExport
      );

      this.successAlert(
        "The file has been sent to email. (If not received, please wait 5-10 minutes.)"
      );
      this.$EventBus.$emit("hideLoading");
    },
    clearExportFilter() {
      this.formExport.email = "";
      this.formExport.user_guid_list = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.font-w-600 {
  font-weight: 600;
}
</style>
